import Head from 'next/head'
import Image from 'next/image'

import { useState, useMemo } from 'react'

import LoginOtp from '@/components/login/Otp'
import LoginConnect from '@/components/login/Connect'

const Index = () => {
    const [emailAddress, setEmailAddress] = useState(null)
    const [redirectToLogin, setRedirectToLogin] = useState(false)

    const initialState = useMemo(
        () => ({
            email: emailAddress || '',
            verificationCode: '',
        }),
        [emailAddress]
    )

    return (
        <div className="w-full">
            <Head>
                <title>{`Log in | ${process.env.NEXT_PUBLIC_APP_NAME}`}</title>
            </Head>
            <div
                style={{
                    backgroundImage: `url("/storage/login-cover.png")`,
                }}
                className="relative h-[190px] w-full overflow-hidden bg-cover ipad:h-[130px] ipad:bg-center mobile:h-[130px]"
            >
                <Image
                    layout="responsive"
                    className="hidden opacity-0"
                    width="100%"
                    height="190px"
                    alt="login"
                    src="/storage/login-cover.png"
                />
            </div>

            {(!emailAddress || redirectToLogin) && (
                <LoginConnect
                    initialState={initialState}
                    setEmailAddress={setEmailAddress}
                    setRedirectToLogin={setRedirectToLogin}
                />
            )}
            {emailAddress && !redirectToLogin && (
                <div>
                    <LoginOtp
                        initialState={initialState}
                        emailAddress={emailAddress}
                    />
                    <div
                        className={`btnClose absolute top-[24px] left-[24px] z-10 cursor-pointer overflow-hidden rounded-full bg-body-bg px-[11px] py-[9px]`}
                        onClick={() => setRedirectToLogin(true)}
                    >
                        <i className="icon-arrow-left-2"></i>
                    </div>
                </div>
            )}
        </div>
    )
}

Index.guest = true

export default Index
