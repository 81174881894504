import styles from '@/styles/Button.module.css'
const Loader = (props) => {
    return (
        <div
            className={` ${props.className} ${
                props.dataLoader
                    ? 'data-loader flex items-center justify-center py-10'
                    : ''
            } ${props.loaderHeight ? props.loaderHeight : 'h-[300px]'}`}
        >
            <span className={styles.spinner}>
                <svg
                    className="text-white m-auto h-auto w-full animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                    ></circle>
                    <path
                        className="fill-common-white"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            </span>
        </div>
    )
}

export default Loader
