import * as Yup from 'yup'
import { login } from './messages'

export default Yup.object().shape({
    verificationCode: Yup.string()
        .trim()
        .required(login.verificationCode.required)
        .min(6, login.verificationCode.min)
        .max(6, login.verificationCode.min),
})
