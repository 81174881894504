import { Formik, Form } from 'formik'
import { useCallback, useEffect, useState } from 'react'

import Alert from '@/components/base/Alert'
import Button from '@/components/base/Button'

const Resend = ({
    setDisabled,
    emailAddress,
    initialState,
    setErrorMessage,
    setSuccessMessage,
}) => {
    const [timer, setTimer] = useState(30)

    useEffect(() => {
        if (!timer) return

        const intervalId = setInterval(() => {
            setTimer(timer - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [timer])

    const handleSubmit = useCallback(
        async (formData, { setSubmitting }) => {
            setSubmitting(false)
            setDisabled(true)
            setErrorMessage(null)

            const authLoginUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/login`

            const fetchOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: emailAddress,
                    permission: 'admin-login',
                }),
            }

            const response = await fetch(authLoginUrl, fetchOptions)

            const data = await response.json()
            if (response.ok) {
                setTimer(30)
                setSuccessMessage(data?.message)
            } else {
                setDisabled(false)
                setErrorMessage(data.message)
            }
        },
        [
            setDisabled,
            setErrorMessage,
            emailAddress,
            setSuccessMessage,
            setTimer,
        ]
    )

    return (
        <Formik
            initialValues={initialState}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ errors, touched }) => {
                const firstKey = Object.keys(errors)[0]
                const showError = !!errors[firstKey] && !!touched[firstKey]

                return (
                    <Form noValidate>
                        <div className="mt-6 text-center ipad:mt-2 mobile:mt-5">
                            <p className="text-interface-300">
                                Haven&apos;t received OTP code yet?
                            </p>
                            {!timer && (
                                <Button
                                    type="submit"
                                    variant="normalizeLink"
                                    text="Resend"
                                    size="xs"
                                    modification="inline-block p-0 underline"
                                />
                            )}

                            {!!timer && (
                                <div className="mt-[6px] flex justify-center">
                                    <p className="text-interface-300">
                                        Resend in
                                    </p>
                                    <div className="ml-2 font-bold text-primary-500">
                                        <span>
                                            {'00'}:
                                            {timer?.toString()?.length === 1
                                                ? `0${timer}`
                                                : timer}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {showError && (
                            <div className="mt-6">
                                <Alert
                                    size="sm"
                                    variant="danger"
                                    message={errors[firstKey]}
                                    icon="inactive"
                                    iconStyle="text-common-white pr-2 relative top-[1px]"
                                    noSpace
                                ></Alert>
                            </div>
                        )}
                    </Form>
                )
            }}
        </Formik>
    )
}

export default Resend
