import Link from 'next/link'
import { Formik, Form } from 'formik'
import { useCallback, useState, useEffect } from 'react'

import Alert from '@/components/base/Alert'
import Button from '@/components/base/Button'
import Resend from '@/components/login/Resend'
import TextBox from '@/components/base/TextBox'

import { useSession } from '@/contexts/Session'
import verifyValidation from '@/validations/verify'

const LoginOtp = ({ initialState, resend, emailAddress }) => {
    const { setAuthToken } = useSession()
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)

    const handleSubmit = useCallback(
        async (
            { verificationCode },
            { setErrors, setSubmitting, resetForm }
        ) => {
            setSubmitting(false)
            setLoading(true)
            setErrorMessage(null)
            setErrors({})

            const authLoginUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/verify-code`

            const fetchOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: emailAddress,
                    verificationCode,
                    permission: 'admin-login',
                }),
            }

            if (!loading) {
                setLoading(true)
                const response = await fetch(authLoginUrl, fetchOptions)

                const data = await response.json()

                if (response.ok) {
                    setAuthToken('accessToken', data?.data?.accessToken, {
                        path: '/',
                        sameSite: true,
                    })

                    setAuthToken('refreshToken', data?.data?.refreshToken, {
                        path: '/',
                        sameSite: true,
                    })
                } else {
                    resetForm()
                    setErrorMessage(data.message)
                    setLoading(false)
                }
            }
        },
        [setAuthToken, emailAddress, loading, setLoading, setErrorMessage]
    )

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage(null)
                setDisabled(false)
            }, 3000)
            return () => clearTimeout(timer)
        }
    }, [successMessage, setSuccessMessage, setDisabled])

    return (
        <div className="relative z-[9] p-[42px] ipad:px-[20px] ipad:py-[24px]">
            <h3 className="mb-3 text-center text-xl ipad:mb-[6px]">
                Enter OTP
            </h3>
            <p className="mb-6 text-center text-base text-interface-300 ipad:mb-[16px]">
                Enter 6 digit OTP code sent to <br />
                <Link href={`mailto:${emailAddress}`}>
                    <a>{emailAddress}</a>
                </Link>
            </p>
            <Formik
                initialValues={initialState}
                validationSchema={verifyValidation}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ errors, touched, submitForm }) => {
                    const firstKey = Object.keys(errors)[0]
                    const showError = !!errors[firstKey] && !!touched[firstKey]

                    return (
                        <Form
                            noValidate
                            onKeyUp={({ target: { name, value } }) => {
                                setErrorMessage(null)
                                if (
                                    name === 'verificationCode' &&
                                    value?.toString()?.length >= 6
                                ) {
                                    submitForm()
                                }
                            }}
                        >
                            <TextBox
                                id="verificationCode"
                                name="verificationCode"
                                size="lg"
                                variant="medium"
                                customcss="tracking-[7px] placeholder:tracking-[0px]"
                                placeholder="Enter OTP"
                                disabled={disabled}
                                maxLength="6"
                            />
                            <Button
                                type="submit"
                                variant="primary"
                                text="Continue"
                                size="md"
                                modification="w-full mb-6 ipad:mb-0"
                                loading={loading}
                                disabled={disabled}
                            />
                            {(showError || errorMessage) && (
                                <div className="mt-6">
                                    <Alert
                                        size="sm"
                                        variant="danger"
                                        message={
                                            errorMessage || errors[firstKey]
                                        }
                                        icon="inactive"
                                        iconStyle="text-common-white pr-2 relative top-[1px]"
                                        noSpace
                                    ></Alert>
                                </div>
                            )}
                            {successMessage && (
                                <div className="mt-6">
                                    <Alert
                                        size="sm"
                                        variant="success"
                                        message={successMessage}
                                        icon="check"
                                        iconStyle="text-common-white pr-2 text-lg relative top-[1px]"
                                    ></Alert>
                                </div>
                            )}
                        </Form>
                    )
                }}
            </Formik>
            {!!emailAddress && (
                <Resend
                    setDisabled={setDisabled}
                    emailAddress={emailAddress}
                    initialState={initialState}
                    setErrorMessage={setErrorMessage}
                    setSuccessMessage={setSuccessMessage}
                />
            )}
        </div>
    )
}

export default LoginOtp
