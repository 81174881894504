import { Formik, Form } from 'formik'
import { useCallback, useState } from 'react'

import Alert from '@/components/base/Alert'
import Button from '@/components/base/Button'
import TextBox from '@/components/base/TextBox'

import loginValidation from '@/validations/login'

const LoginConnect = ({
    initialState,
    setEmailAddress,
    setRedirectToLogin,
}) => {
    const [loading, setLoading] = useState(false)

    const handleSubmit = useCallback(
        async ({ email }, { setErrors, setSubmitting }) => {
            setSubmitting(false)
            setLoading(true)

            const authLoginUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/login`

            const fetchOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    permission: 'admin-login',
                }),
            }

            const response = await fetch(authLoginUrl, fetchOptions)

            const data = await response.json()

            if (response.ok) {
                setEmailAddress(email)
                setRedirectToLogin(false)
            } else {
                setErrors({
                    email: data.message,
                })
            }

            setLoading(false)
        },
        [setEmailAddress, setRedirectToLogin]
    )

    return (
        <div className="relative z-[9] p-[42px]  ipad:px-[20px] ipad:py-[24px]">
            <h3 className="mb-3 text-center text-xl ipad:mb-[6px]">
                Log in to your account
            </h3>
            <p className="mb-6 text-center text-interface-300 ipad:mb-[16px]">
                Please enter your email address
            </p>
            <Formik
                initialValues={initialState}
                validationSchema={loginValidation}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ errors, touched }) => {
                    const firstKey = Object.keys(errors)[0]
                    const showError = !!errors[firstKey] && !!touched[firstKey]

                    return (
                        <Form noValidate>
                            <TextBox
                                id="email"
                                name="email"
                                type="email"
                                size="lg"
                                variant="medium"
                                placeholder="Enter your email address"
                            />
                            <Button
                                type="submit"
                                variant="primary"
                                text="Request OTP"
                                size="md"
                                modification="w-full mb-6 ipad:mb-0"
                                loading={loading}
                            />
                            {showError && (
                                <div className="mt-6">
                                    <Alert
                                        size="sm"
                                        variant="danger"
                                        message={errors[firstKey]}
                                        icon="inactive"
                                        iconStyle="text-common-white pr-2 relative top-[1px]"
                                        noSpace
                                    ></Alert>
                                </div>
                            )}
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default LoginConnect
